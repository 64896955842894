export const personalDataPolicy = {
	header: 'Политика Обработки Персональных Данных',
	data: [
		{
			title: 'Общие Положения',
			text: 'Настоящая Политика обработки персональных данных составлена в соответствии с Федеральным законом «О персональных данных» № 152-ФЗ и определяет порядок обработки персональных данных и меры по обеспечению безопасности персональных данных, предпринимаемые ИП Крюковым Антоном Сергеевичем (далее – "Исполнитель").'
		},

		{
			title: 'Цели Сбора и Обработки Персональных Данных',
			text: 'Обработка персональных данных осуществляется в целях исполнения заключенных с пользователем (далее – "Клиент") договоров, предложения услуг, включая, но не ограничиваясь, доступ к программному обеспечению по модели SaaS.'
		},

		{
			title: 'Согласие на Обработку Персональных Данных',
			text: 'Предоставляя свои персональные данные при регистрации на сайте, Клиент выражает свое согласие на их обработку, включая, но не ограничиваясь: фамилия, имя, отчество, адрес электронной почты, контактный телефон, данные банковских карт.'
		},

		{
			title: 'Условия Обработки Персональных Данных',
			text: `Исполнитель обеспечивает конфиденциальность и безопасность персональных данных в процессе их обработки в соответствии с требованиями законодательства РФ.
Права Клиента
Клиент имеет право на доступ к своим персональным данным, их исправление, удаление и прекращение их обработки, направив соответствующий запрос Исполнителю.
Изменения в Политике Обработки Персональных Данных
Исполнитель оставляет за собой право вносить изменения в настоящую Политику. Новая версия Политики вступает в силу с момента ее размещения на сайте, если иное не предусмотрено новой версией Политики.`
		}
	]
}
