import {
	clearUI,
	setCurrentCompany,
	setCurrentReport,
	setNewCompany
} from './ui/ui.slice'

export const allActions = {
	clearUI,
	setCurrentCompany,
	setCurrentReport,
	setNewCompany
}
