import { FC } from 'react'

import styles from './narrow-attention.module.scss'

const NarrowAttention: FC = () => {
	return (
		<div className={styles.container}>
			<p>Для этой страницы нужен экран пошире...😉</p>
		</div>
	)
}

export default NarrowAttention
