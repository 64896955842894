export const saasContract = {
	header:
		'Публичная оферта о заключении договора о предоставлении доступа к программному обеспечению по модели Saas.',
	data: [
		{
			title: '',
			text: 'Настоящий текст составлен в полном соответствии с пунктом 2 статьи 437 ГК РФ, является публичной офертой, содержащей все существенные условия договора о предоставлении доступа к программному обеспечению по модели Saas. В случае Вашего безоговорочного согласия с условиями предлагаемой публичной оферты, договор о предоставлении доступа к программному обеспечению по модели Saas считается заключенным с момента совершения Вами всех необходимых действий, указанных в тексте публичной оферты, и означает согласие со всеми без исключения и дополнения условиями договора о предоставлении доступа к программному обеспечению по модели Saas.'
		},
		{
			title: '1. Общие положения',
			text: 'Это соглашение заключается между ИП Крюков Антон Сергеевич (далее – "Исполнитель") и пользователем услуг (далее – "Клиент"). ОГРНИП 318784700367261. Контактный email: 22@gkh-pro.ru.'
		},
		{
			title: '2. Предмет соглашения',
			text: `Исполнитель предоставляет Клиенту доступ к программному обеспечению (ПО) в модели Software-as-a-Service (SaaS) для следующих целей​​​​:

Заполнение отчета 22-ЖКХ (Жилище).
Заполнение отчета об исполнении сметы.
Заполнение сметы доходов и расходов.
Подготовка документов для общего собрания.
Формирование документов для взыскания задолженности.`
		},
		{
			title: '3. Условия предоставления ПО',
			text: 'Исполнитель гарантирует актуальность шаблонов отчетов в сервисе. ПО предоставляется как на безвозмездной, так и на возмездной основе​​.'
		},
		{
			title: '4. Ответственность сторон и SLA',
			text: 'Исполнитель не несет ответственность за прямые или косвенные убытки, возникшие в результате использования или невозможности использования ПО. SLA (Service Level Agreement) включает обеспечение сохранности данных, безотказной работы, резервного копирования, доступа в личный кабинет, поддержки​​​​.'
		},
		{
			title: '5. Финансовые условия',
			text: 'Плата за использование ПО (при наличии) взимается с предварительно зачисленных на баланс средств Клиента. Списанная плата не подлежит возврату. Исполнитель применяет УСН, в связи с чем все платежи в его адрес не облагаются НДС.'
		},
		{
			title: '6. Правовые основания',
			text: 'Договор составляется в соответствии с действующим законодательством РФ и регулируется статьями Гражданского кодекса РФ. Подписывая данный договор, Клиент подтверждает свое согласие со всеми условиями и принимает на себя соответствующие обязательства​​.'
		},
		{
			title: '7. Согласие на Получение Уведомлений',
			text: 'Клиент соглашается и подтверждает свое согласие на получение уведомлений технического характера, а также уведомлений об установлении/изменении цен, специальных акциях, предложениях и других коммерческих сообщений от Исполнителя. Эти уведомления могут быть отправлены на адрес электронной почты или через СМС-сообщения на контактный телефон Клиента, указанные при регистрации на сайте или в последующем обновлении контактных данных Клиента.'
		}
	]
}
